/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target, .noUi-target * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-handle {
  background: none repeat scroll 0 0 #2e375f;
    position: relative;
    z-index: 1;

}
.noUi-stacking .noUi-handle {
	/* This class is applied to the lower origin when
   its values is > 50%. */
	z-index: 10;
}
.noUi-stacking + .noUi-origin {
/* Fix stacking order in IE7, which incorrectly
   creates a new context for the origins. */
	*z-index: -1;
}
.noUi-state-tap .noUi-origin {
	-webkit-transition: left 0.3s, top 0.3s;
	transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 10px;
    margin-bottom: 40px;
    margin-top: 40px;
}
.noUi-horizontal .noUi-handle {
	height: 28px;
	left: -17px;
	top: -10px;
	width: 14px;
}
.noUi-horizontal.noUi-extended {
	padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
	right: -15px;
}
.noUi-vertical {
	width: 18px;
}
.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px;
}
.noUi-vertical.noUi-extended {
	padding: 15px 0;
}
.noUi-vertical.noUi-extended .noUi-origin {
	bottom: -15px;
}
/* Styling;
 */
.noUi-background {
	background: #FAFAFA;
	box-shadow: inset 0 1px 1px #f0f0f0;
}
.noUi-connect {
	background: #3FB8AF;
	box-shadow: inset 0 0 3px rgba(51,51,51,0.45);
	-webkit-transition: background 450ms;
	transition: background 450ms;
}
.noUi-origin {
	border-radius: 2px;
}
.noUi-target {
	border: 1px solid #d3d3d3;
}
.noUi-target.noUi-connect {
	box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}
/* Handles and cursors;
 */
.noUi-dragable {
	cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
	cursor: n-resize;
}
.noUi-handle {
	border: 0 none;
	cursor: default;
}
.noUi-active {
	box-shadow: inset 0 0 1px #FFF,  inset 0 1px 7px #DDD,  0 3px 6px -3px #BBB;
}
/* Handle stripes;
 */
.noUi-handle:before, .noUi-handle:after {
	content: "";
	display: block;
	position: absolute;
	height: 14px;
	width: 1px;
	background: #fff;
	left: 4px;
	top: 6px;
}
.noUi-handle:after {
	left: 9px;
}
.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
	width: 14px;
	height: 1px;
	left: 6px;
	top: 14px;
}
.noUi-vertical .noUi-handle:after {
	top: 17px;
}
/* Disabled state;
 */
[disabled].noUi-connect, [disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled] .noUi-handle {
	cursor: not-allowed;
}
