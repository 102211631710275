
@font-face {
  font-family: 'fontello';
  src: url('../../../assets/fonts/autoicon/font/fontello.eot?63088088');
  src: url('../../../assets/fonts/autoicon/font/fontello.eot?63088088#iefix') format('embedded-opentype'),
       url('../../../assets/fonts/autoicon/font/fontello.woff?63088088') format('woff'),
       url('../../../assets/fonts/autoicon/font/fontello.ttf?63088088') format('truetype'),
       url('../../../assets/fonts/autoicon/font/fontello.svg?63088088#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../../../assets/fonts/autoicon/font/fontello.svg?63088088#fontello') format('svg');
  }
  
  
  @font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 400;
  src: local('Play'), url(http://themes.googleusercontent.com/static/fonts/play/v4/dmwh-u_Rcp3MFyefMbpf7A.woff) format('woff');
}
@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 700;
  src: local('Play-Bold'), url(http://themes.googleusercontent.com/static/fonts/play/v4/ExEmzRpc1jg3it614mrxcA.woff) format('woff');
}

}

 
 [class^="auto"]:before, [class*=" auto"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
     
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.auto1:before { content: '\e800'; } /* '' */
.autologo_avto:before { content: '\e813'; } /* '' */
.auto3:before { content: '\e802'; } /* '' */
.auto4:before { content: '\e803'; } /* '' */
.auto5:before { content: '\e804'; } /* '' */
.auto6:before { content: '\e805'; } /* '' */
.auto7:before { content: '\e806'; } /* '' */
.auto8:before { content: '\e807'; } /* '' */
.auto9:before { content: '\e808'; } /* '' */
.auto11:before { content: '\e809'; } /* '' */
.auto2:before { content: '\e801'; } /* '' */
.auto10:before { content: '\e80b'; } /* '' */
.auto13:before { content: '\e80c'; } /* '' */
.auto14:before { content: '\e80d'; } /* '' */
.auto15:before { content: '\e80e'; } /* '' */
.auto16:before { content: '\e80f'; } /* '' */
.auto17:before { content: '\e810'; } /* '' */
.auto18:before { content: '\e811'; } /* '' */
.auto19:before { content: '\e812'; } /* '' */
.auto12:before { content: '\e80a'; } /* '' */





/*ONLY FOR THEME AUTO IMAGE*/



.section-header .autologo_avto {
    color: #000000;
    display: block;
    font-size: 30px;
    height: 38px;
    margin: 0 auto;
    min-height: 16px;
    width: 146px !important;
}


#Services div[data-icon]{
    color: #d2583e;
    font-size: 60px;
    height: 68px;
    margin: 0 auto 20px;
    width: 63px;
		-webkit-transition: all .35s ease;
	-moz-transition: all .35s ease;
	-ms-transition: all .35s ease;
	-o-transition: all .35s ease;
	transition: all .35s ease;
}




.auto1,.auto2,.auto3,.auto4{
color: #D2583E;
    display: block;
    font-size: 60px;
    height: 73px;
    margin: 0 auto 25px;
    width: 100px;
		-webkit-transition: all .35s ease;
	-moz-transition: all .35s ease;
	-ms-transition: all .35s ease;
	-o-transition: all .35s ease;
	transition: all .35s ease;
}

.service-item article:hover i{
	color:#000 !important;
}
.service-item article h4{
		-webkit-transition: all .35s ease;
	-moz-transition: all .35s ease;
	-ms-transition: all .35s ease;
	-o-transition: all .35s ease;
	transition: all .35s ease;
}

.service-item article:hover h4{
	    color: #d2583e !important;
}






 .auto5,.auto6,.auto7{
  color: #FFFFFF;
    float: left;
    font-size: 60px;
    height: 91px;
    margin: 10px 10px 0;
    width: 87px;
		-webkit-transition: all .35s ease;
	-moz-transition: all .35s ease;
	-ms-transition: all .35s ease;
	-o-transition: all .35s ease;
	transition: all .35s ease;

}

.active  .auto5,.active  .auto6,.active  .auto7{
	color: #D2583E !important;
}



.tab-content  .auto5,.tab-content .auto6,.tab-content .auto7{
color: #D2583E !important;
    display: block;
    float: none;
    font-size: 100px;
    height: 155px;
    margin-left: -20px;
    margin-top: -20px !important;
    padding-top: 0 !important;
}







 .auto8,.auto9,.auto10,.auto11{
   border: 1px solid #DFDFDF;
    border-radius: 100px;
    color: #D2583E;
    float: left;
    font-size: 38px;
    height: 53px;
    line-height: 51px;
    margin: 5px 20px 40px 5px;
    padding: 3px 0px 0 0;
    text-align: center;
  
    width: 54px;
		-webkit-transition: all .35s ease;
	-moz-transition: all .35s ease;
	-ms-transition: all .35s ease;
	-o-transition: all .35s ease;
	transition: all .35s ease;

}


.active .auto8,.active  .auto9, .active .auto10, .active .auto11{
	border: 1px solid #D2583E !important;
	background:#D2583E !important;
	color:#fff !important;
}


.auto17{
 position: relative;
    right: -5px;
    top: 3px;
}


.auto17,.auto18,.auto19{
 display: inline-block;
    font-size: 27px;
    width:30px;
}