/*------------------------------------------------------------------
[Table of contents]
- Page Heading                
- Typography            
- Sidebar          
-  Blog           
-  Post Page      
- Comments
-------------------------------------------------------------------*/

/*01. Page Heading*/


.page-title {
	text-transform: uppercase;
	font-size: 22px;
	color: #000;
}
/*02 WORDPRESS Typography */


.clear {
	clear: both;
}
.alignnone {
	margin: 0 20px 20px 0;
}
.aligncenter, div.aligncenter {
	display: block;
	margin: 5px auto;
}
.alignright {
	float: right;
	margin: 5px 0 20px 20px;
}
.alignleft {
	float: left;
	margin: 5px 20px 20px 0;
}
.aligncenter {
	display: block;
	margin: 5px auto;
	text-align: center;
}
.blockquote {
	padding: 20px;
	background: #000;
}
.entry-media .blockquote {
	background: none repeat scroll 0 0 #000;
	color: #fff;
	margin-bottom: 0px;
	padding: 30px 30px 30px 90px;
	position: relative;
	font-family: 'latoitalic';
	font-size: 17px;
	display: inline-block;
}
.entry-media .blockquote a {
	color: #fff;
	font-family: 'latoitalic';
	font-size: 17px;
	text-decoration: none;
}
.blockquote-autor {
	display: inline-block;
	float: right;
	font-family: "oswaldbook";
	font-size: 20px;
	text-transform: uppercase;
	position: relative;
}
.blockquote-autor:after {
	border: 1px solid #ffc908;
	border-left: 0;
	border-right: 0;
	position: absolute;
	padding: 1px;
	top: -7px;
	right: 0;
	content: '';
	display: block;
	width: 40px;
}
/*03 Sidebar */

.widget-title {
	border-bottom: 1px solid #dddddd;
	color: #333333;
	font-family: latoblack;
	font-size: 15px;
	font-weight: normal;
	line-height: 1.333;
	text-transform: uppercase;
	position: relative;
	margin: 0 0 20px;
}
.widget-title:after {
	border-bottom: 1px solid #d74515;
	bottom: -1px;
	content: "";
	display: inline-block;
	left: 0;
	position: absolute;
	width: 15%;
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.widget:hover .widget-title:after {
	width: 30%;
}
.widget-title span {
	border-bottom: 1px solid #dddddd;
	display: inline-block;
	font-family: latoblack;
	font-size: 15px;
	font-weight: normal;
	margin-bottom: 5px;
	padding-bottom: 10px;
	width: 100%;
	position: relative;
}
.widget-title span:after {
	border-bottom: 1px solid #d74515;
	bottom: -1px;
	content: "";
	display: inline-block;
	left: 0;
	position: absolute;
	width: 15%;
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.widget:hover .widget-title span:after {
	width: 30%;
}
.widget {
	margin-bottom: 35px;
}
.widget-filter .block_content li {
	padding: 5px 0;
}
.widget-title .fa {
	color: #d74515;
	font-size: 20px;
	margin-right: 10px;
}
.about-autor .widget-title, .section-comment .widget-title {
	display: inline-block;
}
.block_content {
	color: #888888;
	font-size: 14px;
}
.block_content ul {
	margin: 0;
	padding: 0;
	color: #888888;
	font-size: 14px;
}
.block_content ul li {
	display: inline-block;
	list-style: outside none none;
	margin-bottom: 10px;
	width: 100%;
}
.block_content a {
	color: #888888;
	font-size: 14px;
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.block_content a:hover {
	color: #000;
}
#search-global-form {
	position: relative;
}
#search-global-form input[type=text] {
	width: 100%;
}
#search-global-form button {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
	border: medium none;
	font-size: 14px;
	position: absolute;
	right: 10px;
	top: 10px;
}
.category-list a {
	display: inline-block;
	width: 100%;
}
.category-list .amount-cat {
	color: #d74515;
	float: right;
	margin: 0 20px;
}
.block_content .category-list {
	margin-left: 10px;
}
.product-mini-list .entry-thumbnail {
	float: left;
	margin: 0 5% 5% 0;
	width: 30%;
}
.product-mini-list .entry-thumbnail img {
	width: 100%;
	height: auto;
}
.product-mini-list .entry-main {
	float: left;
	width: 63%;
}
.entry-title a {
	color: #2d375f;
	font-family: "latoregular";
	font-size: 14px;
	font-weight: normal;
	text-transform: none;
}
/*04  Blog */


.entry-header {
	margin-bottom: 10px;
	position: relative;
}
.post {
	margin-bottom: 40px;
}
.entry-thumbnail:hover .bx-prev, .entry-thumbnail:hover .bx-next {
	opacity: 1;
}
.sticky-post {
	background: #ffc908;
	color: #fff;
	display: block;
	font-size: 25px;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	height: 109px;
	line-height: 1;
	padding: 31px 10px 0 32px;
	position: absolute;
	right: -70px;
	text-align: left;
	text-transform: none;
	top: -53px;
	transform: rotate(-45deg);
	width: 100px;
	z-index: 777;
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.sticky-post i {
	font-size: 15px;
	left: -25px;
	position: relative;
	top: -5px;
}
.post-type-media {
	background: none repeat scroll 0 0 #fff;
	bottom: -58px;
	color: #000;
	display: block;
	font-size: 31px;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	height: 150px;
	left: -36px;
	line-height: 1;
	padding: 48px 10px 0 30px;
	position: absolute;
	text-align: right;
	text-transform: none;
	transform: rotate(-45deg);
	width: 100px;
	z-index: 777;
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.post-type-media i {
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	display: block;
}
.box-date-post {
	background: none repeat scroll 0 0 #e21712;
	color: #fff;
	height: 82px;
	left: -20px;
	padding: 10px;
	position: absolute;
	top: 0;
	width: 80px;
	z-index: 78;
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.post:hover .box-date-post {
	top: -10px;
}
.post .entry-media {
	position: relative;
}
.post .entry-media img {
	width: 100%;
	height: auto;
	-moz-transition: all 1s;
	-webkit-transition: all 1s;
	-o-transition: all 1s;
}
.box-date-post {
	text-align: center;
}
.box-date-post .date-1 {
	display: block;
	font-family: "latobold";
	font-size: 26px;
	line-height: 38px;
}
.box-date-post .date-2 {
	font-size: 14px;
}
.box-date-post:before {
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
	content: '';
	border: 12px solid transparent;
	border-top-color: #e21712;
	border-right-color: #e21712;
	position: absolute;
	right: -11px;
	top: 30px;
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
.box-date-post:after {
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
	content: '';
	border: 5px solid transparent;
	border-top-color: #ffc908 !important;
	border-right-color: #ffc908 !important;
	position: absolute;
	left: 2px;
	top: 2px;
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}
.post .entry-media:hover .img-overlay {
	visibility: visible;
	opacity: 1;
}
.post .entry-media .img-overlay {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.4);
	height: 100%;
	opacity: 0;
	padding-top: 50%;
	position: absolute;
	text-align: center;
	-moz-transition: all 1s;
	-webkit-transition: all 1s;
	-o-transition: all 1s;
	visibility: hidden;
	width: 100%;
	z-index: 4;
}
.media-image.format-image:hover .entry-media img {
	position: relative;
	transform: scale(1.15, 1.15);
	transition-dur: 1500ms;
	transition-timing-function: ease-out;
	z-index: 3;
}
.post .entry-media {
	overflow: hidden;
	position: relative;
}
.post .entry-media:after {
	background: rgba(255, 255, 255, 0.4);
	content: "";
	height: 100%;
	left: -150%;
	position: absolute;
	top: 0;
	transition: none 0s ease 0s;
	width: 100%;
	transform: rotate(0deg) scale(1.003) skew(14deg) translate(0px);
	-webkit-transform: rotate(360deg) scale(1.003) skew(14deg) translate(0px);
	-moz-transform: rotate(0deg) scale(1.003) skew(14deg) translate(0px);
	-o-transform: rotate(0deg) scale(1.003) skew(14deg) translate(0px);
	-ms-transform: rotate(0deg) scale(1.003) skew(14deg) translate(0px);
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.post .entry-media:hover:after {
	left: -50%;
}
.post .entry-main {
	position: relative;
}
.post .entry-title {
	margin: 0 0 20px;
	padding: 0;
	text-transform: none;
	clear: both;
}
.post .entry-title > a {
	color: #333333;
	font-family: latobold;
	font-size: 25px;
	font-weight: normal;
	line-height: 27px;
	text-transform: none;
}
.post .entry-content {
	margin-bottom: 10px;
	transition: all 0.3s cubic-bezier(0, 0.3, 0.5, 0.2);
	-ms-transition: all 0.3s cubic-bezier(0, 0.3, 0.5, 0.2);
	-moz-transition: all 0.3s cubic-bezier(0, 0.3, 0.5, 0.2);
	-webkit-transition: all 0.3s cubic-bezier(0, 0.3, 0.5, 0.2);
}
.footer-panel {
	padding: 30px 0 0 0;
	position: relative;
}
.footer-panel:after {
	border: 1px solid #ddd;
	border-left: 0;
	border-right: 0;
	height: 3px;
	content: '';
	display: inline-block;
	width: 100%;
}
.entry-footer {
	padding-top: 20px;
}
.post .entry-media:hover .box-date {
	background-color: #2e375f;
}
.box-date {
	background: none repeat scroll 0 0 #d74516;
	bottom: 0;
	color: #fff;
	display: inline-block;
	font-size: 11px;
	padding: 10px 50px 10px 30px;
	position: absolute;
	right: -20px;
	text-align: center;
	-ms-transform-style: flat;
	transform: rotate(0deg) scale(1.003) skew(26deg) translate(0px);
	-webkit-transform: rotate(0deg) scale(1.003) skew(-26deg) translate(0px);
	-moz-transform: rotate(0deg) scale(1.003) skew(-26deg) translate(0px);
	-o-transform: rotate(0deg) scale(1.003) skew(-26deg) translate(0px);
	-ms-transform: rotate(0deg) scale(1.003) skew(-26deg) translate(0px);
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.box-date .box-date-transform {
	transform: rotate(0deg) scale(1.003) skew(26deg) translate(0px);
	-webkit-transform: rotate(0deg) scale(1.003) skew(26deg) translate(0px);
	-moz-transform: rotate(0deg) scale(1.003) skew(26deg) translate(0px);
	-o-transform: rotate(0deg) scale(1.003) skew(26deg) translate(0px);
	-ms-transform: rotate(0deg) scale(1.003) skew(26deg) translate(0px);
}
.box-date span {
	display: block;
	font-family: latoblack;
	font-size: 24px;
}
.post-grid .entry-title, .post-grid .entry-title a {
	font-family: "latoregular";
	color: #333333;
	font-size: 18px;
}
.post-grid .entry-title {
	margin-bottom: 20px;
}
.post-grid .entry-footer {
	margin: 20px 0 5px;
	padding: 0;
}
.post-grid .box-date {
	background: #fff;
	color: inherit;
}
.post-grid .box-date .fa {
	color: #d74516;
	font-size: 11px;
	left: -2px;
	position: relative;
	top: -2px;
}
.post-grid.post .entry-media:hover .box-date, .post-grid.post .entry-media:hover .box-date .fa {
	color: #fff;
}
.grid-post-button {
	padding-top: 10px;
}
/*Post Page*/

.entry-footer {
	padding-bottom: 20px;
	padding-top: 30px;
}
.footer-panel .social-links {
	display: inline-block;
	margin: 0 0 0 20px;
	padding: 0;
	vertical-align: top;
}
.footer-panel.social-divider .social-links {
	bottom: -30px;
	margin: 0;
	position: relative;
}
.footer-panel.social-divider .social-links li::after {
	display: none;
}
.footer-panel.social-divider .social-links li {
	display: inline-block;
	height: 35px;
	list-style: outside none none;
	margin-right: -3px;
	padding: 8px 15px 6px 17px;
	position: relative;
	transition: all 0.1s ease-out 0s;
	transform: rotate(0deg) scale(1.003) skew(-14deg) translate(0px);
	-webkit-transform: rotate(360deg) scale(1.003) skew(-14deg) translate(0px);
	-moz-transform: rotate(0deg) scale(1.003) skew(-14deg) translate(0px);
	-o-transform: rotate(0deg) scale(1.003) skew(-14deg) translate(0px);
	-ms-transform: rotate(0deg) scale(1.003) skew(-14deg) translate(0px);
}
.footer-panel.social-divider .social-links li a {
	transform: rotate(0deg) scale(1.003) skew(14deg) translate(0px);
	-webkit-transform: rotate(360deg) scale(1.003) skew(14deg) translate(0px);
	-moz-transform: rotate(0deg) scale(1.003) skew(14deg) translate(0px);
	-o-transform: rotate(0deg) scale(1.003) skew(14deg) translate(0px);
	-ms-transform: rotate(0deg) scale(1.003) skew(14deg) translate(0px);
	display: block;
}
.footer-panel .social-box h4 {
	color: #333333;
	display: inline-block;
	font-family: latoregular;
	font-size: 16px;
	margin: 10px 0 0;
	padding: 0;
	vertical-align: top;
}
.footer-panel .social-links li {
	background: none repeat scroll 0 0 #fff;
	border: 1px solid #eeeeee;
	display: inline-block;
	height: 32px;
	list-style: outside none none;
	margin-right: 8px;
	padding: 6px 25px 4px 17px;
	position: relative;
	-webkit-transition: all .1s ease-out;
	-moz-transition: all .1s ease-out;
	-ms-transition: all .1s ease-out;
	-o-transition: all .1s ease-out;
	transition: all .1s ease-out;
}
.footer-panel .social-links li:after {
	background: none repeat scroll 0 0 #fff;
	border-left: 1px solid #eeeeee;
	content: "";
	display: block;
	height: 32px;
	position: absolute;
	right: -9px;
	top: -1px;
	width: 15px;
	transform: rotate(0deg) scale(1.003) skew(-14deg) translate(0px);
	-webkit-transform: rotate(360deg) scale(1.003) skew(-14deg) translate(0px);
	-moz-transform: rotate(0deg) scale(1.003) skew(-14deg) translate(0px);
	-o-transform: rotate(0deg) scale(1.003) skew(-14deg) translate(0px);
	-ms-transform: rotate(0deg) scale(1.003) skew(-14deg) translate(0px);
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.footer-panel .social-links li a {
	color: #333;
	font-family: latobold;
	font-size: 11px;
	text-transform: uppercase;
}
.footer-panel .social-links li .fa {
	font-size: 14px;
	margin-right: 7px;
}
.footer-panel .social-links li:hover .fa, .social-links li:hover a, .social-divider.footer-panel li a, .social-divider.footer-panel li .fa {
	color: #fff !important;
	text-decoration: none;
}
.footer-panel .social-links .social-facebook:hover, .social-divider.footer-panel .social-links .social-facebook {
	background: #3b5998;
}
.footer-panel .social-links li .fa-facebook-square {
	color: #3b5998;
}
.footer-panel .social-links li .fa-twitter-square {
	color: #26c4f1;
}
.footer-panel .social-links .social-twitter:hover, .social-divider.footer-panel .social-links .social-twitter {
	background: #26c4f1;
}
.footer-panel .social-links li .fa-google-plus {
	color: #e93f2e;
}
.footer-panel .social-links .social-google:hover, .social-divider.footer-panel .social-links .social-google {
	background: #e93f2e;
}
.footer-panel .social-links li .fa-pinterest-square {
	color: #b81621;
}
.footer-panel .social-links .social-pinterest:hover, .social-divider.footer-panel .social-links .social-pinterest {
	background: #b81621;
}
.widget-post .meta * {
	font-size: 11px;
}
.widget-post .product-mini-list .entry-thumbnail {
	width: 20%;
}
.social-divider {
	text-align: center;
}
/*05  Comments */



.comments-header {
	z-index: 7;
	background: none repeat scroll 0 0 #000000;
	display: inline-block;
	font-weight: normal;
	margin-bottom: 30px;
	padding: 12px 20px;
	position: relative;
	color: #fff;
	font-size: 16px;
	text-transform: uppercase;
}
.comments-header a {
	color: #fff;
}
.comments-list > li {
	list-style: none;
}
.comments-list > li li {
	position: relative;
}
.comments-list .comment:hover {
	background-color: #f4f4f4;
}
.comments-list {
	margin-bottom: 15px;
	margin-left: 0;
	padding-left: 0;
}
.comment {
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
	border-bottom: 1px solid #eeeeee;
	margin-bottom: 30px;
	padding-bottom: 20px;
	position: relative;
	overflow: hidden;
}
.comment .avatar-placeholder {
	background-color: #e1e1e1;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100px;
	z-index: 9;
}
.comment .avatar-placeholder img {
	width: 100%;
	height: auto;
}
.comment .comment-header {
	margin: 0 0 10px 115px;
	padding-top: 5px;
}
.comment .comment-author {
	color: #333333;
	display: block;
	font-family: latobold;
	font-size: 14px;
	font-style: normal;
	margin-bottom: 5px;
}
.comment .comment-datetime {
	color: #888888;
	font-size: 12px;
	font-weight: normal;
	text-transform: none;
}
.comment-datetime span[class*="icon-"] {
	color: #d74515;
	font-size: 12px;
	font-style: normal;
	margin-right: 3px;
	position: relative;
	top: 0;
}
.comment .comment-reply {
	display: inline-block;
	font-size: 11px;
	font-weight: normal;
	margin-right: 0;
	padding: 5px 12px;
	position: absolute;
	right: 10px;
	text-transform: uppercase;
	top: 10px;
}
.comment .comment-reply:hover i, .comment .comment-reply:hover a {
	color: #000;
}
.comment .comment-reply i {
	color: #ddd;
	display: inherit;
	left: 15px;
	position: absolute;
	top: 6px;
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.comment .comment-reply a {
	color: #ddd;
	padding-left: 21px;
	position: relative;
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-ms-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
.comment .comment-reply a:hover {
	text-decoration: none;
}
.comment .comment-body {
	font-size: 13px;
	line-height: 1.333;
	margin-left: 40px;
	padding: 0 30px 0 75px;
}
.comment .comment-body p {
	margin-bottom: 0;
}
.comments-list .children {
	list-style: none outside none;
	margin: 0 0 0 55px;
	padding: 0;
}
.comments-list > li:last-child, .comments-list .children > li:last-child {
	margin-bottom: 0;
	background: none;
}
#comment-reply-form {
	margin-top: 30px;
}
#comment-reply-form input {
	padding: 10px;
	width: 100%;
	border: 1px solid #eee;
}
.about-autor {
	clear: both;
	margin-bottom: 20px;
	margin-top: 20px;
}
#comment-reply-form textarea {
	height: 120px;
	padding: 10px;
	width: 100%;
	border: 1px solid #eee;
}
.section-comment {
	margin-top: 30px;
}
