/*//////////////////////////////////////////////////////////////////////////////////////////*/
/* LOADER CSS */
/*//////////////////////////////////////////////////////////////////////////////////////////*/

.ua-ie-9 .loaded .ip-header {
	display: none
}

.ip-header .ip-loader svg path.ip-loader-circle {
	-webkit-transition: stroke-dashoffset 0.2s;
	transition: stroke-dashoffset 0.2s;
	stroke: #d74516; /*loader color*/
}

.ip-header {
	text-align: center;
	padding-top: 120px;
	position: fixed;
	top: 0;
	z-index: 99999;
	min-height: 480px;
	width: 100%;
	height: 100%;
  background: none repeat scroll 0 0 #fff;
} 
.ip-header h1 {
	margin: 0;
}
.ip-loader {
	position: absolute;
	left: 0;
	width: 100%;
	opacity: 0;
	cursor: default;
	pointer-events: none;
}
.ip-loader {
	bottom: 50%;
}
.ip-header .ip-inner {
	display: block;
	margin: 0 auto;
}
.ip-header .ip-logo svg {
	min-width: 320px;
	max-width: 480px;
	width: 25%;
}
.ip-header .ip-logo svg path {
	fill: #d74516;
}
.ip-header .ip-loader svg path {
	fill: none;
	stroke-width: 6;
}
.ip-header .ip-loader svg path.ip-loader-circlebg {
	stroke: #ddd;
}
.ip-header .ip-loader svg path.ip-loader-circle {
	-webkit-transition: stroke-dashoffset 0.2s;
	transition: stroke-dashoffset 0.2s;
}

.ip-logo{
	margin-bottom:30px;	
}
/* Content */
.ip-main {
	overflow: hidden;
	margin: 0 auto;
	padding: 160px 0 10em 0;
	max-width: 1100px;
	width: 90%;
}
.ip-main h2 {
	margin: 0;
	padding: 0.5em 0 1em;
	color: #be4856;
	text-align: center;
	font-size: 4.25em;
	font-size: 4vw;
	line-height: 1;
}
#ip-container [class^="icon-"]::before, #ip-container [class*=" icon-"]::before {
	display: block;
	margin-bottom: 0.5em;
	padding: 0.5em;
	border-radius: 5px;
	background: #dfdfdf;
	color: #fff;
	text-align: center;
	text-transform: none;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	font-size: 5em;
	line-height: 1;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* Animations */

/* Initial animation of header elements */
.loading .ip-logo, .loading .ip-loader {
	opacity: 1;
	-webkit-animation: animInitialHeader 1s cubic-bezier(0.7, 0, 0.3, 1) both;
	animation: animInitialHeader 1s cubic-bezier(0.7, 0, 0.3, 1) both;
}
.loading .ip-loader {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
 @-webkit-keyframes animInitialHeader {
 from {
opacity: 0;
-webkit-transform: translate3d(0, 800px, 0);
}
}
 @keyframes animInitialHeader {
 from {
opacity: 0;
-webkit-transform: translate3d(0, 800px, 0);
transform: translate3d(0, 800px, 0);
}
}
/* Header elements when loading finishes */
.loaded .ip-logo, .loaded .ip-loader {
	opacity: 1;
}
.loaded .ip-logo {
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-animation: animLoadedLogo 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
	animation: animLoadedLogo 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}
 @-webkit-keyframes animLoadedLogo {
 to {
-webkit-transform: translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1);
}
}
 @keyframes animLoadedLogo {
 to {
-webkit-transform: translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1);
transform: translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1);
}
}
.loaded .ip-loader {
	-webkit-animation: animLoadedLoader 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
	animation: animLoadedLoader 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}
 @-webkit-keyframes animLoadedLoader {
 to {
opacity: 0;
-webkit-transform: translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1);
}
}
 @keyframes animLoadedLoader {
 to {
opacity: 0;
-webkit-transform: translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1);
transform: translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1);
}
}
/* Change the color of the logo */
.loaded .ip-logo svg path {
	-webkit-transition: all 0.5s ease 0.3s;
	transition: all 0.5s ease 0.3s;
	fill: #fff;
}
/* Header animation when loading finishes */
.loaded .ip-header {
	-webkit-animation: animLoadedHeader 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
	animation: animLoadedHeader 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}
 @-webkit-keyframes animLoadedHeader {
 to {
-webkit-transform: translate3d(0, -100%, 0);
}
}
 @keyframes animLoadedHeader {
 to {
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
}
}
/* Content animations */
.loaded .ip-main h2, .loaded .ip-main .browser, .loaded .ip-main .browser .box, .loaded .codrops-demos {
	-webkit-animation: animLoadedContent 1s cubic-bezier(0.7, 0, 0.3, 1) both;
	animation: animLoadedContent 1s cubic-bezier(0.7, 0, 0.3, 1) both;
}
.loaded .ip-main .browser, .loaded .ip-main .browser .box:first-child {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}
.loaded .ip-main .browser .box:nth-child(2) {
	-webkit-animation-delay: 0.15s;
	animation-delay: 0.15s;
}
.loaded .ip-main .browser .box:nth-child(3) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
 @-webkit-keyframes animLoadedContent {
 from {
opacity: 0;
-webkit-transform: translate3d(0, 200px, 0);
}
}
 @keyframes animLoadedContent {
 from {
opacity: 0;
-webkit-transform: translate3d(0, 200px, 0);
transform: translate3d(0, 200px, 0);
}
}
/* Change layout class for header */
.layout-switch .ip-header {
	position: absolute;
}
/* No JS */
.no-js .ip-header {
	position: relative;
	min-height: 0px;
}
.no-js .ip-header .ip-logo {
	margin-top: 20px;
	height: 180px;
	opacity: 1;
	-webkit-transform: none;
	transform: none;
}
.no-js .ip-header .ip-logo svg path {
	fill: #fff;
}

/* Media queries */

@media screen and (max-width: 45em) {
.ip-main h2 {
	font-size: 2.25em;
	font-size: 10vw;
}
.box {
 width: 100%%;
}
}
