/* Generated by Font Squirrel (http://www.fontsquirrel.com) on February 16, 2015 */



@font-face {
    font-family: 'latolight';
    src: url('../../../assets/fonts/webfontkit/lato-light-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-light-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-light-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/../../../assets/fonts/webfontkit/lato-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoitalic';
    src: url('../../../assets/fonts/webfontkit/lato-italic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-italic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-italic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latohairline_italic';
    src: url('../../../assets/fonts/webfontkit/lato-hairlineitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-hairlineitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-hairlineitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-hairlineitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-hairlineitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latohairline';
    src: url('../../../assets/fonts/webfontkit/lato-hairline-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-hairline-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-hairline-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-hairline-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-hairline-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latobold';
    src: url('../../../assets/fonts/webfontkit/lato-bold-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-bold-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-bold-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latobold_italic';
    src: url('../../../assets/fonts/webfontkit/lato-bolditalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-bolditalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-bolditalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-bolditalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoblack';
    src: url('../../../assets/fonts/webfontkit/lato-black-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-black-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-black-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoblack_italic';
    src: url('../../../assets/fonts/webfontkit/lato-blackitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-blackitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-blackitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-blackitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoregular';
    src: url('../../../assets/fonts/webfontkit/lato-regular-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-regular-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-regular-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latolight_italic';
    src: url('../../../assets/fonts/webfontkit/lato-lightitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-lightitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-lightitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-lightitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latomedium_italic';
    src: url('../../../assets/fonts/webfontkit/lato-mediumitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-mediumitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-mediumitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-mediumitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latomedium';
    src: url('../../../assets/fonts/webfontkit/lato-medium-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-medium-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-medium-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latothin_italic';
    src: url('../../../assets/fonts/webfontkit/lato-thinitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-thinitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-thinitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-thinitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoheavy';
    src: url('../../../assets/fonts/webfontkit/lato-heavy-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-heavy-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-heavy-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-heavy-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-heavy-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoheavy_italic';
    src: url('../../../assets/fonts/webfontkit/lato-heavyitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-heavyitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-heavyitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-heavyitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-heavyitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latothin';
    src: url('../../../assets/fonts/webfontkit/lato-thin-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-thin-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-thin-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-thin-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latosemibold_italic';
    src: url('../../../assets/fonts/webfontkit/lato-semibolditalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-semibolditalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-semibolditalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-semibolditalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latosemibold';
    src: url('../../../assets/fonts/webfontkit/lato-semibold-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/lato-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/lato-semibold-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/lato-semibold-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/lato-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldlight';
    src: url('../../../assets/fonts/webfontkit/oswald-lightitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-lightitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-lightitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-lightitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldheavy';
    src: url('../../../assets/fonts/webfontkit/oswald-heavy-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-heavy-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-heavy-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-heavy-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-heavy-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldheavy';
    src: url('../../../assets/fonts/webfontkit/oswald-heavyitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-heavyitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-heavyitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-heavyitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-heavyitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldextra-light';
    src: url('../../../assets/fonts/webfontkit/oswald-extra-lightitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-extra-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-extra-lightitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-extra-lightitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-extra-lightitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldlight';
    src: url('../../../assets/fonts/webfontkit/oswald-light-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-light-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-light-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldextralight';
    src: url('../../../assets/fonts/webfontkit/oswald-extralight-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-extralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-extralight-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-extralight-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-extralight-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswalddemibold';
    src: url('../../../assets/fonts/webfontkit/oswald-demibold-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-demibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-demibold-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-demibold-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-demibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswalddemi-bold';
    src: url('../../../assets/fonts/webfontkit/oswald-demi-bolditalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-demi-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-demi-bolditalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-demi-bolditalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-demi-bolditalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswald_stencilregular';
    src: url('../../../assets/fonts/webfontkit/oswald-stencil-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-stencil-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-stencil-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-stencil-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-stencil-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldbold';
    src: url('../../../assets/fonts/webfontkit/oswald-bold-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-bold-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-bold-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldbold';
    src: url('../../../assets/fonts/webfontkit/oswald-bolditalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-bolditalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-bolditalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-bolditalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldregular';
    src: url('../../../assets/fonts/webfontkit/oswald-regularitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-regularitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-regularitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-regularitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldmedium';
    src: url('../../../assets/fonts/webfontkit/oswald-medium-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-medium-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-medium-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldmedium';
    src: url('../../../assets/fonts/webfontkit/oswald-mediumitalic-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-mediumitalic-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-mediumitalic-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-mediumitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswald_regularregular';
    src: url('../../../assets/fonts/webfontkit/oswald-regular-webfont.eot');
    src: url('../../../assets/fonts/webfontkit/oswald-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../assets/fonts/webfontkit/oswald-regular-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/webfontkit/oswald-regular-webfont.woff') format('woff'),
         url('../../../assets/fonts/webfontkit/oswald-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}