@font-face {
  font-family: 'icomoon';
  src:  url('../../../assets/fonts/icomoon-v1.0/fonts/icomoon.eot');
  src:  url('../../../assets/fonts/icomoon-v1.0/fonts/icomoon.eot') format('embedded-opentype'),
        url('../../../assets/fonts/icomoon-v1.0/fonts/icomoon.ttf') format('truetype'),
        url('../../../assets/fonts/icomoon-v1.0/fonts/icomoon.woff') format('woff'),
        url('../../../assets/fonts/icomoon-v1.0/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-whatsapp:before {
  content: "\ea93";
}
