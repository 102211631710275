/* The slider */
.iviewSlider {
	overflow: hidden;
}

/* The timer in the Slider */
#iview-timer {
	position: absolute;
	z-index: 100;
	border-radius: 5px;
	cursor: pointer;
}

#iview-timer div {
	border-radius: 3px;
}

/* The Preloader in the Slider */
#iview-preloader {
	position: absolute;
	z-index: 1000;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	border: #000 1px solid;
	padding: 1px 1px 3px 1px;
	width: 100px;
	height: 8px;
}
#iview-preloader div {
	float: left;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	height: 3px;
	background: #000;
	width: 0px;
}

/* The strips and boxes in the Slider */
.iview-strip {
	display:block;
	position:absolute;
	z-index:5;
}
.iview-block {
	display:block;
	position:absolute;
	z-index:5;
}

/* Direction nav styles (e.g. Next & Prev) */
.iview-directionNav a {
	position:absolute;
	top:45%;
	z-index:9;
	cursor:pointer;
}
.iview-prevNav {
	left:0px;
}
.iview-nextNav {
	right:0px;
}

/* Control nav styles (e.g. 1,2,3...) */
.iview-controlNav {
	position:absolute;
	z-index:9;
}
.iview-controlNav a {
	z-index:9;
	cursor:pointer;
}
.iview-controlNav a.active {
	font-weight:bold;
}
.iview-controlNav .iview-items ul {
	list-style: none;
}
.iview-controlNav .iview-items ul li {
	display: inline;
	position: relative;
}
.iview-controlNav .iview-tooltip {
	position: absolute;
}

/* The captions in the Slider */
.iview-caption {
	position:absolute;
	z-index:4;
	overflow: hidden;
	cursor: default;
}

/* The video show in the Slider */
.iview-video-show {
	background: #000;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 101;
}
.iview-video-show .iview-video-container {
	position: relative;
	width: 100%;
	height: 100%;
}
.iview-video-show .iview-video-container a.iview-video-close {
	position: absolute;
	right: 10px;
	top: 10px;
	background: #222;
	color: #FFF;
	height: 20px;
	width: 20px;
	text-align: center;
	line-height: 29px;
	font-size: 22px;
	font-weight: bold;
	overflow: hidden;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}
.iview-video-show .iview-video-container a.iview-video-close:hover {
	background: #444;
}