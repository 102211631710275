
/*//////////////////////////////////////////////////////////////////////////////////////////*/
/* Debugged CSS  */
/*//////////////////////////////////////////////////////////////////////////////////////////*/



/*---------------------------------------------------
  Chrome smooth fonts   <-*** alex zaymund ***->
----------------------------------------------------*/

.box-more-info , .nav-tabs , .carousel-title  .carousel , .list-group-heading {
	-webkit-transform: translate3d(0px, 1px, 0px);
}


/*---------------------------------------------------
  debugging subnav  <-*** alex zaymund ***->
----------------------------------------------------*/

html #main-nav .dropdown-menu {
	right:0;
    left: auto;
	width:100%;
 
}

/*---------------------------------------------------
  Header in small device  <-*** alex zaymund 19.08 ***->
----------------------------------------------------*/
@media (max-width:1030px) {

html .home #main-header {
    position: inherit ;
    width: 100%;
    z-index: 678;
}

html  .cd-nav-trigger {
    z-index: 999 ;
}
}

/*---------------------------------------------------
  Smart menu mobile view <-*** alex zaymund  19.08  ***->
----------------------------------------------------*/

@media (max-width:900px) {
#cd-nav {
    display: block !important;
    opacity: 1 !important;
}
}


/*---------------------------------------------------
  Logo Ipad <-*** alex zaymund  04.09  ***->
----------------------------------------------------*/

@media (min-width:1022px) and (max-width:1229px) {

#logo{
	position:relative;
	z-index:990;
}
}
@media (min-width:1022px) and (max-width:2229px) {

#slider {
    min-height: 343px;
    overflow: auto;
}
}

/*---------------------------------------------------
  Menu Z-Index <-*** alex zaymund  27.01  ***->
----------------------------------------------------*/

.sbHolder{
	z-index:inherit !important;
}


/*---------------------------------------------------
  Scroll top  <-*** alex zaymund  3.04  ***->
----------------------------------------------------*/

.scroll-top-view{
	opacity:1 !important;
}

.scroll-top {
	cursor:pointer;
	opacity:0;
background: #ebecee;
    bottom: 2%;
    display: inline-block;
    padding: 6px;
    position: fixed;
    right: 2%;
    text-align: center;
    text-decoration: none !important;
    width: 38px;
}




/*---------------------------------------------------
  Price table  <-*** alex zaymund  4.04  ***->
----------------------------------------------------*/

html .panel-danger > .panel-heading {
    background-color: #d74516;
    border-color: #d74516;
    color: #ffffff !important;
}

html  .panel-danger .panel-heading  h3 {
    color: #ffffff;
}

html  .panel-heading  h3{
	font-size:18px;
	text-transform:uppercase;
}


html .panel-danger {
    border-color: #dddddd;
}

html .panel-title.price{
	color:#000;
}